<template>
	<div class="p-3 liff-reservation-form">
		<b-card header-tag="header" class="mb-3 liff-reservation-form__card">
			<template #header>
        <div class="d-flex justify-content-between align-items-center">
          <h6 class="mb-0">預約人資訊</h6>
					<button class="btn btn-link btn-sm">儲存</button>
        </div>
      </template>
			<b-form-group label="姓名" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm" class="mb-3">
				<b-form-input v-model="form.name" class="text-right" />
			</b-form-group>
			<b-form-group label="電話" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm">
				<b-form-input v-model="form.phone" class="text-right" />
			</b-form-group>
		</b-card>

		<b-card header-tag="header" class="mb-3 liff-reservation-form__card">
			<template #header>
				<div class="d-flex justify-content-between align-items-center">
					<h6 class="mb-0">預約資訊</h6>
					<button class="btn btn-link btn-sm">儲存</button>
				</div>
			</template>
			<b-form-group label="項目名稱" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm" class="mb-3">
				<b-form-input v-model="form.serviceName" class="text-right" />
			</b-form-group>
			<b-form-group label="服務人員" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm" class="mb-3">
				<b-form-input v-model="form.servicePerson" class="text-right" />
			</b-form-group>
			<b-form-group label="預約時間" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm" class="mb-3">
				<b-form-input v-model="form.serviceTime" class="text-right" />
			</b-form-group>
			<b-form-group label="服務地點" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm" class="mb-3">
				<b-form-input v-model="form.serviceLocation" class="text-right" />
			</b-form-group>
		</b-card>

		<div class="mb-3">
			<b-form-group label="備註">
				<b-form-textarea v-model="form.note" class="liff-reservation-form__textarea" />
			</b-form-group>
		</div>

		<div class="d-flex s-space-x-2">
			<SharedButton class="s-btn-outline-primary w-50">上一步</SharedButton>
			<SharedButton class="s-btn-bg-primary w-50">送出</SharedButton>
		</div>
	</div>
</template>

<script>
import SharedButton from '@/components/Page/Liff/Shared/Button.vue';

	export default {
		components: {
			SharedButton
		},
		data() {
			return {
				form: {
					name: '林曉娟',
					phone: '0987654321',
					serviceName: '精緻剪髮、歐系染髮、洗髮',
					servicePerson: 'Nymphia',
					serviceTime: '2024/01/01 09:00',
					serviceLocation: '台北市中山區中山北路二段36巷36號',
					note: '我幫我媽預約，媽媽姓名王美花',
				}
			}
		}
	}

</script> 

<style lang="scss" scoped>
.liff-reservation-form {
	&__card {
		border-radius: 10px;
		border: none;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
		.card-header {
			border-radius: 10px 10px 0 0;
			border-bottom: 1px solid #E5E5EA;
			font-size: 16px;
			font-weight: 500;
			padding: 16px 16px 12px 16px;
		}
	}
	&__textarea {
		border-radius: 10px;
		border: 1px solid #636366;
		color: #2C2C2E;
		font-size: 16px;
		font-weight: 500;
		line-height: 1.2;
		padding: 16px 12px;
	}

	input {
		font-size: 14px;
		height: auto;
    line-height: 1.2;
		border-bottom: 1px solid #E5E5EA;
    padding-bottom: 6px;
	}
}
</style>